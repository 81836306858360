import { getRestaurantInfo, getVouchersByRestaurant, getVouchersPurchasesByRestaurant, voucherUseAction } from './service'
import { isUsedVoucher, maskedCPF } from './utils'

export default store => ({
  fetchVouchersData: async () => {
    try {
      const restaurantInfo = await getRestaurantInfo()
      const response = await getVouchersByRestaurant()

      return { 
        ...store,
        restaurant_vouchers: response,
        restaurant_info: restaurantInfo
      }
    } catch (err) {
      console.error(err)
      return false
    }
  },
  fetchVouchersPurchasesData: async () => {
    try {
      const response = await getVouchersPurchasesByRestaurant()
      return {
        ...store,
        restaurant_vouchers_purchases: response
      }
    } catch (err) {
      console.error(err)
      return false
    }
  },
  setInitialized: (bool = true) => {
    return {
      ...store,
      initialized: bool
    }
  },
  useVoucher: async (uuid) => {
    let resultedStore = store
    const voucher_purchase = store.restaurant_vouchers_purchases.data.find(x => x.attributes.uuid === uuid)
    console.log(voucher_purchase)
    try {
      await voucherUseAction(uuid)
      document.location.reload()
    } catch (err) {
      console.err(err)
    }

    return resultedStore
  },
  filterVouchers: (state, event) => {
    if (event.target.value.length === 0) {
      return getVouchersByRestaurant().then(r => {
        if (r.vouchers.length) {
          r.vouchers.map(x => x.isAvailable = isUsedVoucher(x))
        }

        return {
          vouchersFilterQuery: '',
          vouchers: r.vouchers
        }
      }).catch(err => console.error(err))
    }

    const getMatch = (arr, str) => {
      const reg = new RegExp(str, 'i');
      
      return arr.filter(function(item) {
        if (item.voucher.match(reg) || item.cpf.match(reg) || maskedCPF(item.cpf).match(reg)) {
          return item;
        }
      });
    }

    if (state.vouchersFilterQuery > event.target.value) {
      return getVouchersByRestaurant().then(r => {
        if (r.vouchers.length) {
          r.vouchers.map(x => x.isAvailable = isUsedVoucher(x))
        }

        return {
          vouchersFilterQuery: event.target.value,
          vouchers: getMatch(r.vouchers, event.target.value)
        }
      }).catch(err => console.error(err))
    }

    return {
      vouchersFilterQuery: event.target.value,
      vouchers: getMatch(state.vouchers, event.target.value)
    }
  }
});