import axios from 'axios'
import { route, getCurrentUrl } from 'preact-router'

const client = (baseUrl) => {
  return axios.create({
    baseURL: baseUrl,
    timeout: 100000,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const vouchersClient = () => client(`${process.env.API_HOST}`)

export const getRestaurantInfo = async () => {
  let response = false
  
  try {
    response = await vouchersClient().request(`${getCurrentUrl()}`)
    response = await response.data
  } catch (err) {
    console.error(err)
    // route('/nao-encontrado', true)
  }
  return response
}

export const getVouchersByRestaurant = async (restaurant) => {
  let response = false
  
  try {
    response = await vouchersClient().request(`${getCurrentUrl()}/restaurant_vouchers`)
    response = await response.data.data
  } catch (err) {
    console.error(err)
    route('/nao-encontrado', true)
  }
  return response
}

export const getVouchersPurchasesByRestaurant = async (restaurant) => {
  let response = false

  try {
    response = await vouchersClient().request(`${getCurrentUrl()}/restaurant_voucher_purchases`)
    response = await response.data
  } catch (err) {
    console.error(err)
  }

  return response
}

export const voucherUseAction = async (voucher) => {
  let response = false

  try {
    response = await vouchersClient().put(`${getCurrentUrl()}/restaurant_voucher_purchases/${voucher}`, { reclaimed: true })
    response = response.data
  } catch (err) {
    console.error(err)
  }

  return response
}