import * as React from 'react'
import { h } from 'preact'
import { Typography } from '@material-ui/core'
import VouchersList from './List'

const StyledTitle = {
  fontWeight: 'bold',
  marginBottom: '1rem'
}

export default ({ payload }) => (
  <section className="vouchers__list">
    <Typography style={StyledTitle}>Itens à venda</Typography>
    <VouchersList data={payload} />     
  </section>
)