import * as React from 'react'
import { h, Fragment } from 'preact'
import { TableRow, TableCell, Checkbox, Button, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { pricefy, maskedCPF } from '../../../js/utils'
import { useState } from 'preact/hooks'
import ReclaimeModalAction from './ReclaimeModalAction'
import { voucherUseAction } from '../../../js/service'
import { connect } from 'redux-zero/preact'

import actions from '../../../js/actions'

export default connect(
    actions
)(({ useVoucher, setInitialized, data, columns }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isItemSelected, setIsItemSelected] = useState(false)
    const [actionModalIsOpened, setActionModalIsOpened] = useState(false)

    const { id } = data
    const mappedData = {
        client: data.meta.user.name,
        cpf: data.meta.user.document,
        code: data.attributes.code,
        credit: data.meta.restaurant_voucher.attributes,
        reclaimed_date: data.attributes.reclaimed_at || 'Não utilizado'
    }

    const handleReclaime = async () => {
        setIsLoading(true)
        await useVoucher(data.attributes.uuid)
        setIsLoading(false)
    }

    const _renderAction = () => {
        if (data.attributes.reclaimed) return <span className="vouchers__purchases--item--action--reclaimed">Utilizado</span>

        return <Button disabled={!isItemSelected} onClick={() => setActionModalIsOpened(true)}>{isLoading ? 'Validando...' : 'Validar manualmente'}</Button>
    }

    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);

    const ColorButton = withStyles((theme) => ({
      root: {
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
    }))(Button);

    const _renderCredit = () => {
        const restaurant_voucher = data.meta.restaurant_voucher.attributes
        if (restaurant_voucher.menu != null) {
            return (
                <HtmlTooltip
                    title={
                      <Fragment>
                        <Typography variant="h5">Menu | {restaurant_voucher.menu.name}</Typography>
                        <div className="voucher__card--content">
                            <Typography><span>Entrada</span></Typography>
                            <Typography>{ restaurant_voucher.menu.starter }</Typography>
                        </div>
                        <div className="voucher__card--content">
                            <Typography><span>Prato principal</span></Typography>
                            <Typography>{ restaurant_voucher.menu.main_dish }</Typography>
                        </div>
                        <div className="voucher__card--content">
                            <Typography><span>Sobremesa</span></Typography>
                            <Typography>{ restaurant_voucher.menu.dessert }</Typography>
                        </div>
                      </Fragment>
                    }
                    >
                    <ColorButton endIcon={<InfoOutlinedIcon fontSize="small" style={{ color: green[500] }}/>}>
                        Menu
                    </ColorButton>
                </HtmlTooltip>
            )
        }

        return pricefy(restaurant_voucher.value)
    }

    return (
        <Fragment>
            <ReclaimeModalAction opened={actionModalIsOpened} handleClose={() => setActionModalIsOpened(false)} handleAction={() => handleReclaime()} submiting={isLoading} />
            <TableRow hover role="checkbox" tabIndex={-1} key={+id} className={`vouchers__purchases--row ${isItemSelected && 'vouchers__purchases--row--selected'}`}>
                <TableCell key={'selector'}>
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': id }}
                        onClick={() => setIsItemSelected(!isItemSelected)}
                        disabled={data.attributes.reclaimed}
                    />
                </TableCell>

                {columns.map((column) => {
                    let value = mappedData[column.id];
                    
                    if (column.id == 'cpf') {
                        value = maskedCPF(value)
                    }

                    if (column.id === 'reclaime_action') {
                        return (
                            <TableCell key={column.id} align={column.align} className="vouchers__purchases--item--action">
                                {_renderAction()}
                            </TableCell>
                        )
                    }

                    if (column.id === 'credit') {
                        return (
                            <TableCell key={column.id} align={column.align}>
                                {_renderCredit()}
                            </TableCell>
                        )
                    }
                    
                    return (
                        <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                    );
                })}
            </TableRow>
        </Fragment>
    )
})