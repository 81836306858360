import createStore from "redux-zero"

const initialStore = {
  initialized: false,
  vouchersFilterQuery: '',
  restaurant_info: {},
  restaurant_vouchers: [],
  restaurant_vouchers_purchases: [],
  loading: false
}

export default createStore(initialStore)
