import * as React from 'react'
import { h } from 'preact'

const NotFoundComponent = () => {
  return (
    <section className="section__notfound">
      <h3>Estabelecimento não encontrado</h3>
      <p>Não conseguimos encontrar o seu estabelecimento, entre em contato pelo e-mail <a href="mailto:meajuda@chefsclub.com.br">meajuda@chefsclub.com.br</a></p>
    </section>
  )
}

export default NotFoundComponent