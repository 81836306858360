import 'babel-polyfill'
import * as React from 'react'
import { h, render } from 'preact'
import VouchersValidatorApp from '../components/VouchersValidatorApp'
import { Provider } from 'redux-zero/preact'

import store from './store'

const mountNode = document.getElementById('vouchersvalidator-app-entry')

render(
  <Provider store={store}>
    <VouchersValidatorApp />
  </Provider>, mountNode, mountNode.lastChild)

if (process.env.NODE_ENV === "production") {
  require("offline-plugin/runtime").install();
}