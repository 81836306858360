import * as React from 'react'
import { h } from 'preact'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import { MenuIcon } from '@material-ui/icons'
import { connect, Connect } from 'redux-zero/preact'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText
  },
}));

const mapToProps = ({ restaurant_info }) => ({ restaurant_info });

export default () => {
  const classes = useStyles();

  return (
    <Connect mapToProps={mapToProps}>
      {({ restaurant_info }) => (
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              ChefsPay - {restaurant_info.name}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
    </Connect>
  )
}