import * as React from 'react'
import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Typography } from '@material-ui/core';
import VouchersPurchaseItem from './Item';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'redux-zero/preact';

const columns = [
  { id: 'client', label: 'Cliente', minWidth: 160 },
  { id: 'cpf', label: 'CPF', minWidth: 100, align: 'right' },
  { id: 'code', label: 'Código', minWidth: 70, align: 'right' },
  { id: 'credit', label: 'Benefício', minWidth: 100, align: 'center' },
  { id: 'reclaimed_date', label: 'Data da visita', minWidth: 100, align: 'right' },
  { id: 'reclaime_action', label: 'Validar cliente', minWidth: 140 }
];

const mapToProps = ({ restaurant_vouchers_purchases }) => ({ restaurant_vouchers_purchases })

export default connect(mapToProps)(({ restaurant_vouchers_purchases, data }) => {
    const [items, setItems] = useState(data.data)
    const { restaurant_voucher_purchases_used, restaurant_voucher_purchases_total } = restaurant_vouchers_purchases.meta

    if (!items.length) return <Typography variant="caption">Nenhum item vendido</Typography>

    const _renderItems = () => items.map((item, index) => <VouchersPurchaseItem data={item} key={index} columns={columns} />)

    return (
        <Paper>
            <TableContainer style={{ maxHeight: 'calc(100vh - 56px - 56px - 56px)' }}>
                <Table stickyHeader aria-label="table">
                    <caption className="vouchers__purchases--caption">
                        <span>Total de itens vendidos: {restaurant_voucher_purchases_total || 0}</span>
                        <span>Total de itens utilizados: {restaurant_voucher_purchases_used || 0}</span>
                    </caption>
                    <TableHead className="vouchers__purchases--head">
                        <TableRow>
                            <TableCell />
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_renderItems()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
})