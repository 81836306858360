export const AlphabeticallySortRule = (a, b) => {
  var _a = a.toUpperCase().normalize("NFD");
  var _b = b.toUpperCase().normalize("NFD");
  return (_a < _b) ? -1 : (_a > _b) ? 1 : 0;
}

export const isUsedVoucher = v => v.used_at !== "Available"

export const getUsedCounter = v => v.filter(x => isUsedVoucher(x)).length

export const maskedCPF = cpf => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") || '-'
}

export const pricefy = value => `R$ ${parseInt(value).toLocaleString()},00`