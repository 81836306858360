import * as React from 'react'
import { h } from 'preact'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import { useState } from 'preact/hooks';

export default ({ opened, handleClose, handleAction, submiting = false }) => {
    return (
        <Dialog onClose={() => handleClose()} aria-labelledby="simple-dialog-title" open={opened} className="reclaime__modal__action--container">
            <DialogContent className="reclaime__modal__action--content">
                <DialogTitle id="simple-dialog-title">Você tem certeza que quer fazer isso?</DialogTitle>
                <Typography>Ao validar um cliente manualmente você está afirmando que ele fez uma visita ao seu estabelecimento e utilizou um ChefsPay dele. Você não pode desfazer essa ação se confirmar.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={submiting}>
                    Cancelar
                </Button>
                <Button onClick={handleAction} autoFocus color="primary" disabled={submiting}>
                    {submiting ? 'Salvando...' : 'Salvar'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}