import * as React from 'react'
import { h } from 'preact'
import { Card, Typography } from '@material-ui/core'
import { pricefy } from '../../js/utils'

export default ({ data }) => {
    const { value, reclaimed, available, sold, active, menu } = data.attributes

    if (menu != null) {
        return (
            <Card className={`voucher__card voucher__card--${active ? 'active' : 'inactive'}`}>
                <Typography variant="h5">Menu | {menu.name}</Typography>
                <div className="voucher__card--content">
                    <Typography>Disponíveis <span>{ available }</span></Typography>
                    <Typography>Vendidos <span>{ sold }</span></Typography>
                    <Typography>Resgatados <span>{ reclaimed }</span></Typography>
                </div>
                <Typography><hr/></Typography>   
                <div className="voucher__card--content">
                    <Typography><span>Entrada</span></Typography>
                    <Typography>{ menu.starter }</Typography>
                </div>
                <div className="voucher__card--content">
                    <Typography><span>Prato principal</span></Typography>
                    <Typography>{ menu.main_dish }</Typography>
                </div>
                <div className="voucher__card--content">
                    <Typography><span>Sobremesa</span></Typography>
                    <Typography>{ menu.dessert }</Typography>
                </div>
            </Card>
        )
    }
    else {
        return (
            <Card className={`voucher__card voucher__card--${active ? 'active' : 'inactive'}`}>
                <Typography variant="h5">Crédito de {pricefy(value)} </Typography>
                <div className="voucher__card--content">
                    <Typography>Disponíveis <span>{ available }</span></Typography>
                    <Typography>Vendidos <span>{ sold }</span></Typography>
                    <Typography>Resgatados <span>{ reclaimed }</span></Typography>
                </div>
                
            </Card>
        )
    }
}