import * as React from 'react'
import { h } from 'preact'
import Router, { route } from 'preact-router'
import Match from 'preact-router/match'
import { useState, useEffect } from 'preact/hooks'
import { Connect } from 'redux-zero/preact'
import NotFoundRoute from './404'
import Loading from './Loading'
import Header from './Header'
import Vouchers from './Vouchers'
import VouchersPurchases from './Vouchers/Purchases'
import { ThemeProvider } from '@material-ui/core/styles'
import Theme from './Theme'

import actions from '../js/actions'
import { Grid } from '@material-ui/core'

const VouchersRoute = () => {
  return (
    <Connect actions={actions} mapToProps={({ initialized, restaurant_vouchers, restaurant_vouchers_purchases }) => ({ initialized, restaurant_vouchers, restaurant_vouchers_purchases })}>
      {({ initialized, restaurant_vouchers, restaurant_vouchers_purchases, fetchVouchersData, fetchVouchersPurchasesData, setInitialized }) => {

        useEffect(async () => { 
          await fetchVouchersData()
          await fetchVouchersPurchasesData()
          setInitialized()
        }, [])

        if (!initialized) return <Loading />

        return (
          <Grid container className="app__vouchers--container">
            <Grid item sm={12} className="app__vouchers--sidebar">
              {!!restaurant_vouchers && <Vouchers payload={restaurant_vouchers} />}
            </Grid>
            <Grid item sm={12} className="app__vouchers--content">
              {!!restaurant_vouchers_purchases && <VouchersPurchases payload={restaurant_vouchers_purchases} />}
            </Grid>
          </Grid>
        )
      }}
    </Connect>
  )
}

export default () => {
  return (
    <ThemeProvider theme={Theme}>
      <Header />
      <main>
        <Router>
          <Match path="/">
            { ({ matches }) => matches && route('/nao-encontrado', true) }
          </Match>
          <VouchersRoute path="/:voucherHash" />
          <NotFoundRoute path="/nao-encontrado" />
        </Router>
      </main>
    </ThemeProvider>
  )
}